'use client';

import type { FC } from 'react';
import { useBrandLocaleValue } from '@/react/hooks/useBrandLocaleValue';
import { msg } from '@/services/isomorphic/I18NService';
import { Icon, IconSizeProp, IconTypes } from '@/react/components/core-ui/Icon';

import S from '../../styles.base.module.scss';
import { header_navigation_close } from "@/lang/__generated__/ahnu/header_navigation_close";
import { header_navigation_back } from "@/lang/__generated__/ahnu/header_navigation_back";

/**
 * The properties for the NavigationBack component.
 */
interface INavigationBackProps {
  /**
   * The function to call when the back button is clicked.
   */
  onClick: () => void;
}

/**
 * Mobile navigation back button.
 */
export const NavigationBack: FC<INavigationBackProps> = ({ onClick }) => {
  const ButtonIconType = useBrandLocaleValue<IconTypes>(
    () => ({
      default: IconTypes.AngleLeft,
      AHNU: IconTypes.CloseLight
    }),
    []
  );

  // Check to see if the brand has a custom icon size for the back button icon.
  const navCategoryIconSize = useBrandLocaleValue<IconSizeProp>(
    () => ({
      default: IconSizeProp.SizeSM
    }),
    []
  );

  return (
    <button
      type="button"
      className={S.back}
      onClick={onClick}
      aria-label={msg(header_navigation_close)}
    >
      <Icon
        icon={ButtonIconType}
        size={navCategoryIconSize}
        className={S.backIcon}
      />
      <span className={S.backText}>{msg(header_navigation_back)}</span>
    </button>
  );
};

export default NavigationBack;

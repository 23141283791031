import { useContext } from 'react';
import { DrawerContext } from '../DrawerContext';

/**
 * Provides information about all open drawers.
 * @returns The ids of all open drawers.
 * @throws Error if used outside of a DrawersProvider.
 */
export const useOpenDrawers = (): Set<string> => {
  const state = useContext(DrawerContext);

  if (!state) {
    throw new Error('useOpenDrawers must be used within a DrawerProvider');
  }

  return state;
};

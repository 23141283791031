'use client';

import { Suspense, type FC } from 'react';
import UserButton from '@/react/components/navigation/Header/components/UserButton';
import { MiniCartButton } from '@/react/components/cart/MiniCartButton';
import type { INavigationContent } from '@/services/models/Content';
import type { Nullable } from '@/type-utils';
import SkeletonLoader from '@/react/components/utility/SkeletonLoader';
import { DrawerProvider } from '@/react/components/core-ui/Drawer/DrawerContext';
import Search from '../../../../Search';
import { Mobile as MobileNavbar } from '../..';
import { HeaderLogo } from '../../../../HeaderLogo';

import S from './styles.base.module.scss';

export interface IMobileHeaderProps {
  /**
   * Is search enabled on this component.
   */
  isSearchEnabled: boolean;
}

/**
 * The mobile header that sits within the header.
 */
export const MobileHeader: FC<IMobileHeaderProps> = ({ isSearchEnabled }) => {
  return (
    <>
      <div className={S.columnLeft}>
        <Suspense fallback={<SkeletonLoader />}>
          <DrawerProvider>
            <MobileNavbar />
          </DrawerProvider>
        </Suspense>
      </div>
      <HeaderLogo />
      <div className={S.columnRight}>
        {isSearchEnabled && <Search isInlineToggleable />}
        <Suspense fallback={<SkeletonLoader />}>
          <UserButton />
        </Suspense>
        <Suspense fallback={<SkeletonLoader />}>
          <MiniCartButton />
        </Suspense>
      </div>
    </>
  );
};

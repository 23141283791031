import type { FC } from 'react';
import { msg } from '@/services/isomorphic/I18NService';
import { IconTypes, Icon } from '@/react/components/core-ui/Icon';
import { classes } from '@/next-utils/css-utils/scss-utils';
import { Link } from '@/react/components/core-ui/Link';
import ConfigurationService from '@/services/isomorphic/ConfigurationService';
import { useDrawerActions } from '@/react/components/core-ui/Drawer/hooks';
import type { IAccountUtilityLinkProps } from './IAccountUtilityLinkProps';

import { MobileDrawer } from '../../MobileDrawer';
import S from '../../../styles.base.module.scss';
import { MobileNavItem } from '../../MobileNavItem';
import { NavigationLink } from '../../NavigationLink';
import { header_utilBar_account } from "@/lang/__generated__/ahnu/header_utilBar_account";
import { header_utilBar_myAccount } from "@/lang/__generated__/ahnu/header_utilBar_myAccount";
import { login_title } from "@/lang/__generated__/ahnu/login_title";
import { account_wishlist_title } from "@/lang/__generated__/ahnu/account_wishlist_title";
import { account_createAccount_title } from "@/lang/__generated__/ahnu/account_createAccount_title";
import { account_accountInformation_orderHistory } from "@/lang/__generated__/ahnu/account_accountInformation_orderHistory";
import { account_title } from "@/lang/__generated__/ahnu/account_title";

/**
 * Component housing links and functionality related to user accounts (e.g. login, register, account, wishlist).
 * Will show different links based on whether the user is logged in or not.
 * @param isLoggedIn - Whether the user is logged in.
 */
export const AccountUtilityLink: FC<IAccountUtilityLinkProps> = ({
  isLoggedIn
}) => {
  // Get the menu configuration to determine if the wishlist link should be shown.
  const menuConfig = ConfigurationService.getConfig('menu');
  const showWishlistLink = menuConfig.getSetting(
    'mainMenu.showWishlistLink'
  ).value;

  const { closeAllDrawers } = useDrawerActions();

  return (
    <MobileDrawer
      labelClassName={S.utilityLink}
      label={
        !isLoggedIn
          ? msg(header_utilBar_account)
          : msg(header_utilBar_myAccount)
      }
      icon={IconTypes.User}
    >
      <ul className={S.nav}>
        <MobileNavItem>
          <Link
            href="/account"
            className={classes(S.navLink, S.navLinkHeader, S.labelContainer)}
            onClick={closeAllDrawers}
          >
            <Icon icon={IconTypes.User} />
            {!isLoggedIn
              ? msg(header_utilBar_account)
              : msg(header_utilBar_myAccount)}
          </Link>
        </MobileNavItem>

        {!isLoggedIn ? (
          <>
            <MobileNavItem>
              <NavigationLink
                item={{
                  href: '/login',
                  label: msg(login_title),
                  id: 'login'
                }}
              />
            </MobileNavItem>
            {showWishlistLink && (
              <MobileNavItem>
                <NavigationLink
                  item={{
                    href: '/wishlist',
                    label: msg(account_wishlist_title),
                    id: 'wishlist'
                  }}
                />
              </MobileNavItem>
            )}
            <MobileNavItem>
              <NavigationLink
                item={{
                  href: '/login?tab=1',
                  label: msg(account_createAccount_title),
                  id: 'register'
                }}
              />
            </MobileNavItem>
          </>
        ) : (
          <>
            <MobileNavItem>
              <NavigationLink
                item={{
                  href: '/orders',
                  label: msg(account_accountInformation_orderHistory),
                  id: 'order-history'
                }}
              />
            </MobileNavItem>
            {showWishlistLink && (
              <MobileNavItem>
                <NavigationLink
                  item={{
                    href: '/wishlist',
                    label: msg(account_wishlist_title),
                    id: 'wishlist'
                  }}
                />
              </MobileNavItem>
            )}
            <MobileNavItem>
              <NavigationLink
                item={{
                  href: '/account',
                  label: msg(account_title),
                  id: 'my-account'
                }}
              />
            </MobileNavItem>
          </>
        )}
      </ul>
    </MobileDrawer>
  );
};

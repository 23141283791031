'use client';

import { FC } from 'react';
import { Icon, IconTypes, IconSizeProp } from '@/react/components/core-ui/Icon';
import { Drawer } from '@/react/components/core-ui/Drawer';
import { useBrandLocaleValue } from '@/react/hooks/useBrandLocaleValue';
import { classes } from '@/next-utils/css-utils/scss-utils';
import { useDrawer } from '@/react/components/core-ui/Drawer/hooks';
import { NavigationBack } from '../NavigationBack';
import type { IMobileDrawerProps } from './IMobileDrawerProps';
import { MobileNavItem } from '../MobileNavItem';

import S from '../../styles.base.module.scss';

/**
 * A wrapper component for the typical layout for a mobile drawer (includes a back button, icon, and label).
 *
 * **Note:** This component is intended to be used within the @see Mobile drawer component.
 * @param children - The children to render in the drawer.
 * @param label - The label to display in the drawer.
 * @param icon - The icon to display in the drawer (next to the label).
 * @param labelClassName - The class name to apply to the label.
 */
export const MobileDrawer: FC<IMobileDrawerProps> = ({
  children,
  label,
  icon,
  labelClassName
}) => {
  const { isOpen, openDrawer, closeDrawer } = useDrawer();

  // Check to see if the brand has a custom icon size for the category nav icon.
  const navArrowIconSize = useBrandLocaleValue<IconSizeProp>(
    () => ({
      default: IconSizeProp.SizeSM
    }),
    []
  );

  const navLabelIconSize = useBrandLocaleValue<IconSizeProp>(
    () => ({
      default: IconSizeProp.SizeLG
    }),
    []
  );

  return (
    <MobileNavItem>
      <button
        type="button"
        className={classes(S.navLink, labelClassName)}
        onClick={openDrawer}
        aria-label={label}
      >
        <span className={classes(S.labelContainer)}>
          {icon != null && <Icon icon={icon} size={navLabelIconSize} />}
          {label}
        </span>
        <span className={S.navIcon}>
          <Icon icon={IconTypes.AngleRight} size={navArrowIconSize} />
        </span>
      </button>
      <Drawer
        rootClassName={S.innerDrawerRoot}
        drawerClassName={S.innerDrawer}
        curtainClassName={S.innerDrawerCurtain}
        open={isOpen}
        fromLeft
      >
        <NavigationBack onClick={closeDrawer} />
        {children}
      </Drawer>
    </MobileNavItem>
  );
};

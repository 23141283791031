import { useId } from 'react';
import { useDrawerActions } from './useDrawerActions';
import { useOpenDrawers } from './useOpenDrawers';

import type { IDrawer } from '../types/IDrawer';

/**
 * Provides the information about a single drawer's actions.
 * The actions on this hook are called with the id (instead of receiving the id as an argument, like in {@link ./useDrawerActions.ts useDrawerActions}).
 * Use this hook in the parent component for {@link ../Drawer.tsx Drawer} to control the drawer's state.
 * @example
 * ```tsx
 * // Using a single drawer
 * import type { FC } from 'react';
 * import { useDrawer } from '@/react/components/core-ui/Drawer/hooks';
 * import { Drawer } from '@/react/components/core-ui/Drawer';
 *
 * const Menu:FC = () => {
 *   const { isOpen, openDrawer, closeDrawer } = useDrawer();
 *   return (
 *     <>
 *       <button onClick={openDrawer}>Open Drawer</button>
 *       <Drawer isOpen={isOpen} onClose={closeDrawer}>
 *           <div>Drawer Content</div>
 *       </Drawer>
 *     </>
 *   );
 * };
 *
 * // Using multiple drawers
 * import type { FC } from 'react';
 * import { useDrawer, useDrawerActions } from '@/react/components/core-ui/Drawer/hooks';
 * import { Drawer } from '@/react/components/core-ui/Drawer';
 *
 * const Menu:FC = () => {
 *   const { closeAllDrawers } = useDrawerActions();
 *   const { isOpen: isOpen1, openDrawer: openDrawer1, closeDrawer: closeDrawer1 } = useDrawer();
 *   const { isOpen: isOpen2, openDrawer: openDrawer2, closeDrawer: closeDrawer2 } = useDrawer();
 *
 *   return (
 *       <>
 *          <button onClick={closeAllDrawers}>Close All</button>
 *          <button onClick={openDrawer1}>Open Drawer 1</button>
 *          <Drawer isOpen={isOpen1} onClose={closeDrawer1}>
 *            <div>Drawer 1 Content</div>
 *          </Drawer>
 *          <button onClick={openDrawer2}>Open Drawer 2</button>
 *          <Drawer isOpen={isOpen2} onClose={closeDrawer2}>
 *            <div>Drawer 2 Content</div>
 *          </Drawer>
 *       </>
 *     );
 *   };
 * ```
 * @returns The drawer's id, whether it is open, and functions to open and close it. Also provides a function to close all drawers.
 * @throws Error if used outside of a {@link ../DrawerContext.tsx DrawerProvider}.
 */
export const useDrawer = (): IDrawer => {
  const id = useId();
  const openDrawers = useOpenDrawers();
  const { closeDrawer, openDrawer, closeAllDrawers } = useDrawerActions();

  const isOpen = openDrawers.has(id);

  return {
    id,
    isOpen,
    closeDrawer: () => closeDrawer(id),
    openDrawer: () => openDrawer(id),
    closeAllDrawers
  };
};

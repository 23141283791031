'use client';

import { FunctionComponent } from 'react';
import { Link } from '@/react/components/core-ui/Link';
import { msg } from '@/services/isomorphic/I18NService';
import { useLogout } from '@/react/hooks/useLogout';
import { Icon, IconTypes, IconSizeProp } from '@/react/components/core-ui/Icon';
import { classes } from '@/next-utils/css-utils/scss-utils';
import { EventType } from '@/services/isomorphic/UserInteractionService';
import { useBrandLocaleValue } from '@/react/hooks/useBrandLocaleValue';
import { useDrawerActions } from '@/react/components/core-ui/Drawer/hooks';
import { AccountUtilityLink } from './AccountUtilityLink';

import S from '../../styles.base.module.scss';
import { MobileNavItem } from '../MobileNavItem';
import { ChooseCountryUtilityLink } from './ChooseCountryUtilityLink';
import { header_utilBar_storeLocator } from "@/lang/__generated__/ahnu/header_utilBar_storeLocator";
import { header_utilBar_customerService } from "@/lang/__generated__/ahnu/header_utilBar_customerService";
import { account_authenticated_logout } from "@/lang/__generated__/ahnu/account_authenticated_logout";

/**
 * Utility links that include: My Account, Store Locator, and Country Selector links. Used in the mobile navigation drawer.
 */
export const MobileUtilityLinks: FunctionComponent = () => {
  // Logout hook to handle logging out the user.
  const { isLoggedIn, logOut } = useLogout();
  const { closeAllDrawers } = useDrawerActions();
  const storeLocatorLabel = msg(header_utilBar_storeLocator);
  const customerServiceLabel = msg(header_utilBar_customerService);
  const logoutLabel = msg(account_authenticated_logout);

  // Determine if the brand should show the logout icon next to the logout text.
  const logoutIconType = useBrandLocaleValue<IconTypes>(
    () => ({
      default: IconTypes.AngleLeft
    }),
    []
  );

  return (
    <>
      <MobileNavItem>
        <span className={classes(S.navLink, S.labelContainer)}>
          <Link
            className={S.utilityLink}
            href="/help-center"
            onClick={closeAllDrawers}
            interactionDetails={{
              action: EventType.NavigationLink,
              link: {
                linkName: customerServiceLabel,
                linkCategory: 'top-navigation',
                linkSection: customerServiceLabel
              }
            }}
          >
            {customerServiceLabel}
          </Link>
        </span>
      </MobileNavItem>
      <AccountUtilityLink isLoggedIn={isLoggedIn} />
      <MobileNavItem>
        <span className={classes(S.navLink, S.labelContainer, S.utilityLink)}>
          <Icon icon={IconTypes.MapMarker} size={IconSizeProp.Size1X} />
          <Link
            className={S.utilityLink}
            href="/storelocator"
            onClick={closeAllDrawers}
            interactionDetails={{
              action: EventType.NavigationLink,
              link: {
                linkName: storeLocatorLabel,
                linkCategory: 'top-navigation',
                linkSection: storeLocatorLabel
              }
            }}
          >
            {storeLocatorLabel}
          </Link>
        </span>
      </MobileNavItem>
      {isLoggedIn && (
        <MobileNavItem>
          <Link className={S.utilityLink} href="/" onClick={closeAllDrawers}>
            <button
              type="button"
              className={classes(S.navLink, S.utilityLink)}
              onClick={logOut}
              aria-label={msg(account_authenticated_logout)}
            >
              <span className={classes(S.labelContainer)}>
                <Icon
                  icon={logoutIconType}
                  size={IconSizeProp.SizeSM}
                  className={S.logoutIcon}
                />
                {logoutLabel}
              </span>
            </button>
          </Link>
        </MobileNavItem>
      )}
      <ChooseCountryUtilityLink />
    </>
  );
};

export default MobileUtilityLinks;

'use client';

import { FC } from 'react';
import I18NService, {
  msg,
  getCountryName,
  getLanguageName
} from '@/services/isomorphic/I18NService';
import { IconTypes } from '@/react/components/core-ui/Icon';

import { classes } from '@/next-utils/css-utils/scss-utils';
import { MobileDrawer } from '../../MobileDrawer';
import { MobileNavItem } from '../../MobileNavItem';
import S from '../../../styles.base.module.scss';
import { NavigationLink } from '../../NavigationLink';
import { header_utilBar_viewMoreCountries } from "@/lang/__generated__/ahnu/header_utilBar_viewMoreCountries";

/**
 * Country selector that redirects to the selected country page.
 */
export const ChooseCountryUtilityLink: FC = () => {
  const { country, language } = I18NService.currentLocale;
  const flagIconType = `${country}Flag` as keyof typeof IconTypes;
  const countryString = getCountryName(country);
  const languageString = getLanguageName(language);

  const label = `${languageString} | ${countryString}`;
  const chooseCountryLabel = msg(header_utilBar_viewMoreCountries);

  return (
    <MobileDrawer
      labelClassName={classes(S.utilityLink)}
      label={label}
      icon={IconTypes[flagIconType]}
    >
      <ul className={S.nav}>
        <MobileNavItem>
          <NavigationLink
            item={{
              href: '/choose-country',
              label: chooseCountryLabel,
              id: 'choose-country'
            }}
          />
        </MobileNavItem>
      </ul>
    </MobileDrawer>
  );
};

import { useContext } from 'react';
import type { IDrawerActions } from '../types/IDrawerActions';
import { DrawerActionsContext } from '../DrawerContext';

/**
 * Provides the actions for all drawers, and the ability to close/open drawers by id.
 * @returns The actions for all drawers.
 * @throws Error if used outside of a {@link ../DrawerContext.tsx DrawerProvider}.
 */
export const useDrawerActions = (): IDrawerActions => {
  const actions = useContext(DrawerActionsContext);

  if (!actions) {
    throw new Error('useDrawerActions must be used within a DrawerProvider');
  }

  return actions;
};
